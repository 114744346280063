// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-input--ui-input{box-sizing:border-box}._-_-_-ant-src-components-ui-input--ui-input__search-prefix{color:#8C8C8C}._-_-_-ant-src-components-ui-input--ui-input__search-clear{cursor:pointer;color:#8C8C8C}._-_-_-ant-src-components-ui-input--ui-input__search-clear:hover,._-_-_-ant-src-components-ui-input--ui-input__search-clear:focus{color:var(--myteam-themeVariables-colorBrand)}\n", ""]);
// Exports
exports.locals = {
	"ui-input": "_-_-_-ant-src-components-ui-input--ui-input",
	"uiInput": "_-_-_-ant-src-components-ui-input--ui-input",
	"ui-input__search-prefix": "_-_-_-ant-src-components-ui-input--ui-input__search-prefix",
	"uiInput__searchPrefix": "_-_-_-ant-src-components-ui-input--ui-input__search-prefix",
	"ui-input__search-clear": "_-_-_-ant-src-components-ui-input--ui-input__search-clear",
	"uiInput__searchClear": "_-_-_-ant-src-components-ui-input--ui-input__search-clear"
};
module.exports = exports;
