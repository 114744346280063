// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-menu-title--profile-menu-title{flex:1;display:flex;justify-content:space-between}._-_-_-profile-frontend-src-components-profile-menu-title--profile-menu-title__header{font-weight:600;font-size:15px;line-height:20px;letter-spacing:0.1px;color:#DBDBDB}\n", ""]);
// Exports
exports.locals = {
	"profile-menu-title": "_-_-_-profile-frontend-src-components-profile-menu-title--profile-menu-title",
	"profileMenuTitle": "_-_-_-profile-frontend-src-components-profile-menu-title--profile-menu-title",
	"profile-menu-title__header": "_-_-_-profile-frontend-src-components-profile-menu-title--profile-menu-title__header",
	"profileMenuTitle__header": "_-_-_-profile-frontend-src-components-profile-menu-title--profile-menu-title__header"
};
module.exports = exports;
