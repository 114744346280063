// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item{height:32px;margin-top:4px;padding:0;border:none;font-weight:normal;color:#DBDBDB}._-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item:hover,._-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item:hover:not(:focus):not([disabled]),._-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item:active,._-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item:focus{background-color:transparent;border:none;box-shadow:none}._-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item__icon{margin-right:8px}._-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item__title{color:inherit}\n", ""]);
// Exports
exports.locals = {
	"user-item": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item",
	"userItem": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item",
	"user-item__icon": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item__icon",
	"userItem__icon": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item__icon",
	"user-item__title": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item__title",
	"userItem__title": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items-user-item--user-item__title"
};
module.exports = exports;
