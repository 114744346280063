// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__avatars{display:flex;align-items:center;flex-direction:column;margin:2px 0}._-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__title{color:#212121}._-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__content{display:flex;align-self:center}._-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__avatar{margin:4px}._-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__icon{width:32px;height:32px;padding:0;margin:4px;border:none;border-radius:50%;color:#212121;background:#8C8C8C}\n", ""]);
// Exports
exports.locals = {
	"avatars-tooltip__avatars": "_-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__avatars",
	"avatarsTooltip__avatars": "_-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__avatars",
	"avatars-tooltip__title": "_-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__title",
	"avatarsTooltip__title": "_-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__title",
	"avatars-tooltip__content": "_-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__content",
	"avatarsTooltip__content": "_-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__content",
	"avatars-tooltip__avatar": "_-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__avatar",
	"avatarsTooltip__avatar": "_-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__avatar",
	"avatars-tooltip__icon": "_-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__icon",
	"avatarsTooltip__icon": "_-_-_-ant-src-components-widgets-AvatarsTooltip--avatars-tooltip__icon"
};
module.exports = exports;
