// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-gamification-item--gamification-item{position:relative;display:inline-flex;margin:16px 0 0 16px}._-_-_-profile-frontend-src-components-gamification-item--gamification-item:focus{box-shadow:none}._-_-_-profile-frontend-src-components-gamification-item--gamification-item__count{font-weight:700;font-size:12px;line-height:20px;right:0;bottom:0;position:absolute;padding:0 6px;height:auto;border:none;color:#212121;border-radius:12px;min-width:20px;background:#DBDBDB}\n", ""]);
// Exports
exports.locals = {
	"gamification-item": "_-_-_-profile-frontend-src-components-gamification-item--gamification-item",
	"gamificationItem": "_-_-_-profile-frontend-src-components-gamification-item--gamification-item",
	"gamification-item__count": "_-_-_-profile-frontend-src-components-gamification-item--gamification-item__count",
	"gamificationItem__count": "_-_-_-profile-frontend-src-components-gamification-item--gamification-item__count"
};
module.exports = exports;
