// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header{display:flex;justify-content:space-between;flex-wrap:wrap}._-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header_with-border{border-bottom:1px solid #363636}._-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__title-wrapper{position:relative;display:flex;align-items:center;flex:1;min-height:68px;max-width:100%;padding:24px}._-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__title-wrapper_bigger-padding-right{padding-right:54px}._-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__title-text{font-weight:700;font-size:17px;line-height:20px;letter-spacing:0.1px;max-width:100%}._-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__close-icon{position:absolute;right:24px;cursor:pointer;color:#8C8C8C;width:20px;height:20px}._-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__step-back-icon{cursor:pointer;margin-right:24px}._-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__badge{margin-left:8px}._-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__buttons{display:flex;flex-wrap:nowrap;padding-right:24px;align-items:center}._-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__buttons>:not(:last-child){margin-right:16px}._-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__under-title{flex-basis:100%}\n", ""]);
// Exports
exports.locals = {
	"ui-modal-header": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header",
	"uiModalHeader": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header",
	"ui-modal-header_with-border": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header_with-border",
	"uiModalHeader_withBorder": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header_with-border",
	"ui-modal-header__title-wrapper": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__title-wrapper",
	"uiModalHeader__titleWrapper": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__title-wrapper",
	"ui-modal-header__title-wrapper_bigger-padding-right": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__title-wrapper_bigger-padding-right",
	"uiModalHeader__titleWrapper_biggerPaddingRight": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__title-wrapper_bigger-padding-right",
	"ui-modal-header__title-text": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__title-text",
	"uiModalHeader__titleText": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__title-text",
	"ui-modal-header__close-icon": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__close-icon",
	"uiModalHeader__closeIcon": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__close-icon",
	"ui-modal-header__step-back-icon": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__step-back-icon",
	"uiModalHeader__stepBackIcon": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__step-back-icon",
	"ui-modal-header__badge": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__badge",
	"uiModalHeader__badge": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__badge",
	"ui-modal-header__buttons": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__buttons",
	"uiModalHeader__buttons": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__buttons",
	"ui-modal-header__under-title": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__under-title",
	"uiModalHeader__underTitle": "_-_-_-ant-src-components-ui-modals-modal-header--ui-modal-header__under-title"
};
module.exports = exports;
