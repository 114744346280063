// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-drawer--ui-drawer .ant-drawer-close:focus{box-shadow:none}._-_-_-ant-src-components-ui-drawer--ui-drawer__close-icon{color:#8C8C8C}\n", ""]);
// Exports
exports.locals = {
	"ui-drawer": "_-_-_-ant-src-components-ui-drawer--ui-drawer",
	"uiDrawer": "_-_-_-ant-src-components-ui-drawer--ui-drawer",
	"ui-drawer__close-icon": "_-_-_-ant-src-components-ui-drawer--ui-drawer__close-icon",
	"uiDrawer__closeIcon": "_-_-_-ant-src-components-ui-drawer--ui-drawer__close-icon"
};
module.exports = exports;
