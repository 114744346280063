// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__header{display:flex;align-items:center}._-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__coins{display:flex;color:#EF4362;position:absolute;right:68px}._-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__coins-count{margin-left:8px}._-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__content{padding:24px;border-top:1px solid #363636}._-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__list-empty{text-align:center;padding:12px 16px;border-radius:4px;background:#2E2E2E}\n", ""]);
// Exports
exports.locals = {
	"transaction-history__header": "_-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__header",
	"transactionHistory__header": "_-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__header",
	"transaction-history__coins": "_-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__coins",
	"transactionHistory__coins": "_-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__coins",
	"transaction-history__coins-count": "_-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__coins-count",
	"transactionHistory__coinsCount": "_-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__coins-count",
	"transaction-history__content": "_-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__content",
	"transactionHistory__content": "_-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__content",
	"transaction-history__list-empty": "_-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__list-empty",
	"transactionHistory__listEmpty": "_-_-_-profile-frontend-src-components-profile-transactions-history--transaction-history__list-empty"
};
module.exports = exports;
