// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-image--ui-image{display:flex;border-radius:4px}._-_-_-ant-src-components-ui-image--ui-image__loading,._-_-_-ant-src-components-ui-image--ui-image__preview{background:var(--myteam-themeVariables-colorBrand16)}._-_-_-ant-src-components-ui-image--ui-image__placeholder{margin:auto;align-self:center;color:#212121}._-_-_-ant-src-components-ui-image--ui-image__image{width:100%;object-fit:cover;border-radius:4px}\n", ""]);
// Exports
exports.locals = {
	"ui-image": "_-_-_-ant-src-components-ui-image--ui-image",
	"uiImage": "_-_-_-ant-src-components-ui-image--ui-image",
	"ui-image__loading": "_-_-_-ant-src-components-ui-image--ui-image__loading",
	"uiImage__loading": "_-_-_-ant-src-components-ui-image--ui-image__loading",
	"ui-image__preview": "_-_-_-ant-src-components-ui-image--ui-image__preview",
	"uiImage__preview": "_-_-_-ant-src-components-ui-image--ui-image__preview",
	"ui-image__placeholder": "_-_-_-ant-src-components-ui-image--ui-image__placeholder",
	"uiImage__placeholder": "_-_-_-ant-src-components-ui-image--ui-image__placeholder",
	"ui-image__image": "_-_-_-ant-src-components-ui-image--ui-image__image",
	"uiImage__image": "_-_-_-ant-src-components-ui-image--ui-image__image"
};
module.exports = exports;
