// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-contacts-social-link--social-link{color:#212121;height:32px;margin-right:8px;margin-bottom:8px}._-_-_-profile-frontend-src-components-contacts-social-link--social-link_button{font-weight:600;font-size:13px;line-height:14px;letter-spacing:0.1px;background:#92B2EF;padding:9px 12px;border-radius:32px}\n", ""]);
// Exports
exports.locals = {
	"social-link": "_-_-_-profile-frontend-src-components-contacts-social-link--social-link",
	"socialLink": "_-_-_-profile-frontend-src-components-contacts-social-link--social-link",
	"social-link_button": "_-_-_-profile-frontend-src-components-contacts-social-link--social-link_button",
	"socialLink_button": "_-_-_-profile-frontend-src-components-contacts-social-link--social-link_button"
};
module.exports = exports;
