// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-tabs--tabs .ant-tabs-nav-wrap{padding:0 24px}._-_-_-ant-src-components-ui-tabs--tabs .ant-tabs-nav{margin:0}._-_-_-ant-src-components-ui-tabs--tabs .ant-tabs-nav:before{border-bottom:none}._-_-_-ant-src-components-ui-tabs--tabs .ant-tabs-tab{font-weight:700;font-size:15px;line-height:20px;letter-spacing:0.1px;margin:0 24px 0 0;padding:22px 0;height:64px}._-_-_-ant-src-components-ui-tabs--tabs .ant-tabs-tab:hover{box-shadow:inset 0px -2px 0px var(--myteam-themeVariables-colorBrandFaded)}._-_-_-ant-src-components-ui-tabs--tabs .ant-tabs-tab .ant-tabs-tab-btn{display:flex;font-weight:bold;color:#9EA1A3}._-_-_-ant-src-components-ui-tabs--tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:#DBDBDB}._-_-_-ant-src-components-ui-tabs--tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{background:var(--myteam-themeVariables-colorHoverOnBrand)}\n", ""]);
// Exports
exports.locals = {
	"tabs": "_-_-_-ant-src-components-ui-tabs--tabs"
};
module.exports = exports;
