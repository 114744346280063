// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-components-frontend-src-components-slots-table-slot--slots-table-slot{position:absolute;background-color:var(--myteam-themeVariables-colorBrand16);border:1px solid #212121;z-index:1;transition:background-color 0.3s ease}._-_-_-components-frontend-src-components-slots-table-slot--slots-table-slot:hover{background-color:var(--myteam-themeVariables-colorBrand32)}._-_-_-components-frontend-src-components-slots-table-slot--slots-table-slot_selected{background-color:var(--myteam-themeVariables-colorBrandFaded)}._-_-_-components-frontend-src-components-slots-table-slot--slots-table-slot_free{opacity:0;background-color:#4277B8}._-_-_-components-frontend-src-components-slots-table-slot--slots-table-slot_free:hover{background-color:#4277B8;opacity:1;z-index:2}\n", ""]);
// Exports
exports.locals = {
	"slots-table-slot": "_-_-_-components-frontend-src-components-slots-table-slot--slots-table-slot",
	"slotsTableSlot": "_-_-_-components-frontend-src-components-slots-table-slot--slots-table-slot",
	"slots-table-slot_selected": "_-_-_-components-frontend-src-components-slots-table-slot--slots-table-slot_selected",
	"slotsTableSlot_selected": "_-_-_-components-frontend-src-components-slots-table-slot--slots-table-slot_selected",
	"slots-table-slot_free": "_-_-_-components-frontend-src-components-slots-table-slot--slots-table-slot_free",
	"slotsTableSlot_free": "_-_-_-components-frontend-src-components-slots-table-slot--slots-table-slot_free"
};
module.exports = exports;
